.user-leaderboard {
    box-sizing: border-box;
    max-width: 90vw;
    width: 600px;
    max-height: 63vh;
    overflow-y: scroll;
    background: #FFFFFF;
    /* border: 1px solid #7C7C7C; */
    border-radius: 11px;
    padding: 15px
}

.user-leaderboard .trophy {
    float: left;
    filter: contrast(0%)
}

.user-leaderboard .texts {
    padding-left: 54px;
}

.user-leaderboard .texts h1 {
    font-family: 'AG Book Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: left;

    /* identical to box height, or 48px */

    letter-spacing: 0.04em;
    margin: 0;
}

.user-leaderboard .texts p {
    font-family: 'AG Book Rounded';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* identical to box height, or 36px */
    letter-spacing: 0.04em;
    margin: 0;
    /* Passive Grey */
    color: #7C7C7C;
    text-align: left;
}

.user-leaderboard table {
    border-collapse: collapse;
    width: 100%;
}

.user-leaderboard th,
.user-leaderboard td {
    border-bottom: 1px solid #ddd;
    height: 65px;
    box-sizing: border-box;
}



.user-leaderboard tr:hover {
    background-color: rgb(80, 229, 255);
}

.user-leaderboard tbody .in {
    width: 40px;
}

.user-leaderboard tbody .na {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-left: 15px;
}

.user-leaderboard tbody .active {
    color: #327BB9;
}