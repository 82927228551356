.card-login h3 {
  margin: 8px auto;
}

form {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.input-field {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin: 5px;
  width: 70%;
  border-radius: 11px;
}

.loading-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-login {
  margin-top: 10vh;
}

.pin-modal {
  text-decoration: underline;
  color: rgb(78, 194, 248);
  cursor: pointer;
}