.timer {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  margin-top: 40px;
  width:auto;

  background: rgba(0, 0, 0, 0.51);
  border-radius: 21px;
}

.numbercard-container {
  flex: auto;
  flex-direction: row;
  align-self: center;
  /* padding: 10px; */
}

.numbercard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
  padding: 0px 10px;

  color: rgba(255, 255, 255, 0.87);
}

.number {
  color: rgba(255, 255, 255, 0.87);
  background-color: #49A8E1;
  margin: 10px;
  padding: 10px;
  border-radius: 10%;
}

@media(max-width: 1920px) {
  .timer {
    padding-top: 0px;
    margin-bottom: 20px;
  }
}