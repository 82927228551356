.modal {
  display: flex;
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(100, 100, 100, 0.6);
}

.choose-avatar .modal-body {
  height: 80%;
  margin: auto;

  align-items:center;
  justify-content:center;

  padding: 5px;
}
