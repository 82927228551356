.name-plate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 20px;

}

.user-icon {
  height: 35px;
  width: 35px;
  background-color: #c4c4c4;
  border-radius: 50%;
  margin-right: 10px;
}

.invisible {
  visibility: hidden;
}

