.Card {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  max-width: 600px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;

  /* Shadow */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 11px;

}

@media(max-width: 1920px) and (min-width: 600px) {
  .Card {
    margin-top: 0;
    margin-bottom: 15px;
  }
}
