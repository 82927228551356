.lobby {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  transition: background-color 0.3s ease-out;
}

.header {
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
}

.img-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.img-container .white-logo {
  margin: 0 auto;
}

.match-title {
  width: 200px;
  height: 50px;
  margin: 0px auto;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  margin-bottom: 20px;
}

.match-title-text {
  color: white;
}

.match-disclaimer {
  color: white;
  margin-top: 50px;
}

.fun-fact {
  background-color: #00000084;
  box-sizing: border-box;
  padding: 24px;
  padding-top: 14px;
  color: white;
  max-width: 90vw;
  width: 700px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 8px;
}

.fun-fact h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 200%;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0;
  color: #FFFFFF;
}

.fun-fact p {
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0;
  color: #FFFFFF;
}