.tournament-plate {
  background-color: white;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 11px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
  width: 500px;
  /* margin-inline: auto;
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 10px;
  background: #ffffff; */

  /* Shadow */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}

.filter-blue {
  filter: invert(53%) sepia(16%) saturate(6238%) hue-rotate(185deg) brightness(78%) contrast(81%);
  /* CSS filter generator: https://codepen.io/sosuke/pen/Pjoqqp */
}

.info-set {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: #327BB9;

  /* width: 400px; */
  /* margin-right: 1rem; */
}


.match-title {
  height: 50px;
  background: rgba(0 ,0 ,0 ,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 50px;
}

.match-title-text {
  color: white; 
}

@media(max-width: 425px) {
  .tournament-plate {
    width: 379px;
  }
} 

@media(max-width: 375px) {
  .tournament-plate {
    margin-left: 40px;
  }
} 