.queue {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    transition: background-color .3s ease-out;
    background-image: url('../../../assets/patterns/playworks patterns_ro-sham-bo.png');
    background-size: 40%;
    /* padding-bottom: 30px; */
}

.game-loading {
    transition: opacity .3s ease-out;
}

.game-start-headline {
    font-size: 3rem;
    color: white;
    animation: floatUp .3s ease-out forwards;
}

.game-counter {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 24px;
    padding: 10px;
    border-radius: 5px;

    width: 700px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.game-score {
    width: 720px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.game-selection-title {
    color: white;
    margin-bottom: 10px;
}

.game-selection-headline {
    font-size: 3rem;
    color: white;
    line-height: 36px;
    margin-top: 20px;
}

.game-selection-subtitle {
    color: white;
    text-align: center;
    margin-top: 10px;
}

.game-selection-card {
    max-width: 200px;
    height: 265px;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: transform .1s ease-out;
}

.game-selection-card:hover {
    transform: scale(1.05);
}

.game-selection-card img{
    max-width: 80%;
    height: auto;
}

.game-selection-cards {
    margin-top: 20px;
    width: 720px;
    max-width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 35px;
}

.game-selection-option-sub {
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
}

.game-selection-option-sub p {
    border-radius: 5px;
    margin: 0;
    box-sizing: border-box;
    padding: 5px;
    background-color: #789742;
}

.game-selection-option-sub p:first-child {
    background-color: #AB3339;
    margin-bottom: 5px;
}

.countdown-circle {
    margin-top: 25px;
    font-size: 3rem;
    color: white;
    height: 80px;
    width: 80px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: floatUp .3s ease-out forwards;
    transition: opacity .2s ease-out;
}

.game-timer {
    position: fixed;
    right: 10px;
    top: 5px;
}

.user-vs-text {
    width: 120px;
    font-size: 3rem;
    color: white;
}

.go-to-button {
    background-color: #49A8E1;
    color: white;
    width: 500px;
    border-radius: 5px;

    margin-top: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.game-started {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.countdown-disclaimer {
    width: 136px;
    text-align: left;
    color: white;
    margin-top: 25px;
}

.btn-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
}

.exit-btn {
    position: fixed;
    top: 10px;
    left: 10px;
    color: white;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #2222224b;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exit-btn button {
    color: white;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
}

.quit-btn {
    background-color: #AB3339;
    padding: 5px;
    border: none;
    border-radius: 5px;
    color: white;
}

.cancel-btn {
    background-color: #49A8E1;
    padding: 5px;
    border: none;
    border-radius: 5px;
    color: white;
}

@keyframes floatUp {
    from {
        transform: translateY(-10px)
    }

    to {
        transform: translateY(0px)
    }
}