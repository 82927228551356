.game {
  background-color: #8b3e7f;
  position:fixed;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
}

.header {
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
}

.align-cards {
  flex: auto;
  flex-direction: row;
  padding: 50px;
  width: auto;
}

.img-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.img-container .white-logo{
  margin: 0 auto
}

.game-loading {
  transition: opacity .3s ease-out;
}

.game-start-countdown {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.game-start-headline {
    margin-top: 50px;
    font-size: 3rem;
    
    color: white;
    animation: floatUp .3s ease-out forwards;
}

.countdown-disclaimer {
  width: 136px;
  text-align: left;
  color: white;
  margin-top: 25px;
  
}
