.partner-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 320px;
    max-width: 320px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
}

.partner-container img {
    width: 50%;
    height: auto;
}

@media(max-width: 1200px) {
    .partner-container {
        width: 283px;
        /* height: 112px; */
    }
}
@media(max-width: 1100px) {
    .partner-container {
        width: 23vw;
        /* height: 112px; */
    }
}