@font-face {
  font-family: "AG BOOK ROUNDED";
  src: local("AG BOOK ROUNDED"), url(../src/assets/fonts/AGBookRouCFFBol.otf);
}

body {
  /* margin: 0 !important; */
  margin: auto;
  font-family: "AG BOOK ROUNDED", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(1, 61, 105);
  background-image: url('./assets/patterns/playworks patterns_ro-sham-bo.png');
  background-size: 40%;
  /* max-width: 600px; */

}

@font-face {
  font-family: 'AG Book Rounded';
  src: url('./assets/fonts/AG\ Book\ Rounded\ Medium.otf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: AG Book Rounded;
  font-style: normal;
  font-weight: bold;
  font-size: 96px;
  line-height: 100%;
  /* identical to box height, or 96px */

  letter-spacing: 0.04em;

  color: #000000;
}

h2 {
  font-family: AG Book Rounded;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 100%;
  /* identical to box height, or 60px */

  letter-spacing: 0.04em;

  color: #000000;
}

h3 {
  font-family: AG Book Rounded;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 200%;
  letter-spacing: 0.04em;

  color: #000000;
}

.body1 {
  font-family: Arial;
  font-size: 16px;
  line-height: 200%;
  text-align: initial;
  /* identical to box height, or 32px */

  letter-spacing: 0.04em;

  color: #000000;
}

.body2 {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  /* identical to box height, or 28px */

  letter-spacing: 0.04em;

  color: #000000;
}

.body-bold {
  font-family: Arial;
  font-size: 16px;
  line-height: 200%;
  /* identical to box height, or 32px */
  letter-spacing: 0.04em;

  color: #000000;
}

.subtitle {
  /* font-family: AG Book Rounded; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 200%;
  text-align: initial;

  /* or 36px */
  letter-spacing: 0.04em;

  color: rgba(0, 0, 0, 0.87);
}

.btn-text {
 /* Text */


position: static;
width: 46px;
height: 16px;
left: 173.5px;
top: 24px;

/* Button Text */

/* font-family: Outfit; */
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 100%;
/* identical to box height, or 16px */

text-align: center;
letter-spacing: 0.1em;
text-transform: uppercase;

color: #FFFFFF;
}
