.App {
  text-align: center;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.color-logo {
  position: static;
  width: 292px;
  height: 146px;
  left: 180px;
  top: 32px;
  margin: 16px 0px;
}

.white-logo {
  margin: 30px auto;
  position: static;
  width: 136px;
  height: 76px;
  left: 0px;
  top: 36px;
}

@media(min-width: 2560px) {
  .white-logo {
    width: 183px;
    height: 112px;
  }
}
@media (max-width:1920px){
  .white-logo {
    margin-top: 20px;
  }
}


/* Modal */
