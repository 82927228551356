.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;

  /* Bright Blue */
  background: #49a8e1;
  border-radius: 15px;
  border-color: transparent;
}

.btn:hover {
  background: #62c3ff;
  transform: scale(0.95);
}

.btn:active {
  background: #49a8e1;
  transform: scale(1.05); /* hehe */
}
.btn:disabled {
  background: #d3d7d9 !important;
  transform: scale(1.05); /* hehe */
}
