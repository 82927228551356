.loading-circle {
    background: rgba(0, 0, 0, 0.5);
    max-height: 100px;
    max-width: 100px;
    width: 10vw;
    height: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
}

.overlay {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 10;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-container {
    animation: animate 1.5s linear forwards infinite;
    transform: scale(0.7);
}

.spinner-container svg {
    transform: scale(0.7);
}

@keyframes animate {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}