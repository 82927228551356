.user-vs-text {
  width: 120px;
  font-size: 3rem;
  color: white;
}

.user-score {
  width: 250px;
  padding: 5px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.user-name-section {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.player-picture {
  height: 35px;
  width: 35px;
  background-color: #c4c4c4;
  border-radius: 50%;
  margin-right: 10px;
}

.user-wins {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.user-wins-text {
  color: white;
}

.user-wins-circle {
  height: 10px;
  width: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #c4c4c4;
  transition: background-color .3s ease-out;
}

.player-score {
  height: 15px;
  width: 15px;
  margin: 0 10px;
  border-radius: 50%;
}