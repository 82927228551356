.choose-avatar {
  max-width: 600px;
  margin: auto;
}

.avatar-icon {
  width: 215px;
  height: 213px;

  padding: 20px;

  /* background: #7e7c7f; */

  border-radius: 50%;

  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-name: wiggle;
}

@keyframes wiggle {
  from {
    /* transform: rotate(0deg); */
    transform: scale(1);
  }
  75% {
    /* transform: rotate(0deg); */
    transform: scale(1);
  }
  80% {
    /* transform: rotate(15deg); */
    transform: scale(1.1);
  }
  85% {
    /* transform: rotate(-15deg); */
    transform: scale(0.9);
  }
  90% {
    /* transform: rotate(15deg); */
    transform: scale(1.1);
  }
  95% {
    /* transform: rotate(-15deg); */
    transform: scale(1);
  }
}

.avatar-icon:hover {
  transform: scale(1.4) !important;
  animation: none;
}

.choose-avatar-header {
  padding: 1px;
}

.choose-icon-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 120px);
  grid-row-gap: 1.5em;
  grid-column-gap: 1em;
}

@media only screen and (max-width : 420px) {
    .choose-icon-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

.choose-icon {
  display: grid;
  width: 75px;
  padding: 15px;
}

.choose-icon:hover {
  transform: scale(1.5);
  /* background-color: #7e7c7f; */
  border-radius: 11px;
}

.choose-avatar-btn:hover {
  transform: scale(1.5);
  color: #446ccf;
  cursor: pointer;
}
