.not-contention-box {
    position: fixed;
    box-sizing: border-box;
    padding: 5px 10px;
    top: 10px;
    left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.603);
    border-radius: 8px;
}

.not-contention-box p {
    font-size: 12px;
    max-width: 200px;
}

.not-contention-box2 {
    position: fixed;
    box-sizing: border-box;
    padding: 5px 10px;
    top: 10px;
    right: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.603);
    border-radius: 8px;
}

.not-contention-box2 button {
    color: white;
    background: none;
    border: none;
    border-radius: 8px;
}